import React, { useState, useEffect } from "react";
import { Container } from "../Elements/Container";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import ReactHtmlParser from "react-html-parser";
import { Link } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
const StyledBoxes = styled.section`
  color: #555;
  .content {
    p {
      margin: 0;
      /* transition: 1s cubic-bezier(0.42, 0, 0.35, 0.93); */
      background: #0000008f;
      color: white;
      padding: 0 10px;
      visibility: hidden;
      opacity: 0;
      span {
        font-size: 100px;
        margin-bottom: 1.6em;
      }
      @media screen and (max-width: 768px) {
        background: #ffffff8f;
        color: black;
      }
    }
  }
  .gatsby-image-wrapper {
    -webkit-transition: -webkit-filter 1s;
    transition: -webkit-filter 1s;
    transition: filter 1s;
    transition: filter 1s, -webkit-filter 1s;
  }
  .title {
    font-weight: 300;
    width: 100%;
    color: #333;
    padding-left: 5vw;
    padding-right: 5vw;
    text-align: center;
    margin-bottom: 2.5rem; 
  }
  .animated {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.42, 0, 0.35, 0.93);
    transition: -webkit-transform 1s cubic-bezier(0.42, 0, 0.35, 0.93);
    transition: transform 1s cubic-bezier(0.42, 0, 0.35, 0.93);
    transition: transform 1s cubic-bezier(0.42, 0, 0.35, 0.93),
      -webkit-transform 1s cubic-bezier(0.42, 0, 0.35, 0.93);
  }
  .grid-grow {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    margin: auto;
    @media (max-width: 1024px) {
      .grid-item {
        flex-basis: 50% !important;
      }
    }
    @media (max-width: 1024px) {
      .grid-item {
        flex-basis: 50% !important;
      }
    }
    @media (max-width: 1024px) {
      .grid-item {
        flex-basis: 100% !important;
        max-width: 450px;
      }
    }
    .grid-item {
      flex-basis: 33.33%;
      -ms-flex: auto;
      position: relative;
      padding: ${rhythm(0.5)};
      box-sizing: border-box;
      &.long-nc {
        @media screen and (max-width: 1024px) {
          z-index: 1;
          max-height: 300px;
          overflow: hidden;
          margin-bottom: 1rem;
        }
      }
      a {
        border: 1px solid hsla(0, 0%, 60%, 0.5);
        max-width: 480px;
        text-decoration: none;
        display: flex;
        margin: 0.2rem auto;
        flex-direction: column;
        color: #555;
        text-align: center;
        &.long {
          max-width: 530px;
        }
        &.box-wrapper {
        
          &.deselected {
           
          }
          &.selected {
            p {
              opacity: 1 !important;
              visibility: visible !important;
            }
            .gatsby-image-wrapper {
              -webkit-filter: brightness(0.5);
              filter: brightness(0.5);
            }
            .indicator {
              i {
                background: #91c508;
              }
            }
          } 
        }
        &.box-wrapper:hover {
          paragraph-hover {
            opacity: 1 !important;
            visibility: visible !important;
          }
          .gatsby-image-wrapper {
            -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
          }
          .indicator-hover {
            i {
              background: #91c508;
            }
          }
        }

        h5 {
          margin-bottom: 0.5rem;
          margin-top: ${rhythm(1)};
          text-transform: uppercase;
          font-weight: 400;
          color: #333;
        }
        .title {
          
          letter-spacing: 1px;
          color: #9a9a9a;
          text-transform: uppercase;
        }
        .indicator {
          margin-bottom: ${rhythm(1)};
          display: flex;
          justify-content: center;
          i {
            -webkit-transition: background 1s cubic-bezier(0.42, 0, 0.35, 0.93);
            transition: background 1s cubic-bezier(0.42, 0, 0.35, 0.93);
            background: #222;
            padding: 5px;
            border-radius: 50%;
            color: #fff;
            font-size: 15px;
          }
        }
      }
    }
  }
`;

const arr = ["", "", ""];

const Boxes = (props) => {

  const [toggle, setActiveToggle] = useState(false);
  const [currentBox, setCurrentBox] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    
    if(typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
  },[]);

  const length = arr.length;
  return (
    <StyledBoxes>
      <Container
        style={
          props.internal
            ? {
                padding: `4rem calc(5vw - 0.8rem) 3rem`,
              }
            : {
                padding: `4rem calc(5vw - 0.8rem) 3rem`,
              }
        }
        justifyConten="center"
        alignItem="center"
        flexDirection="column"
      >
        {!props.internal && ReactHtmlParser(props.title)}

        <div className="grid-grow">
          {props.procedures.map((i, k) => {
            const key = `wrapper-${k}`;
            return (
              <div
                key={k}
                className={`grid-item ${
                  props.procedures.length <= 3 && !props.content && "long-nc"
                }`}
              >
                <a
                  onClick={(e) => {
                    props.content && e.preventDefault();
                    setCurrentBox(i);
                    setTimeout(() => {
                      if(i.to) {
                        window.location.href = i.to
                      }
                    },1000);
                  }}
                  className={`animated box-wrapper ${
                    toggle === key ? "selected" : "deselected"
                  } ${length <= 3 && "long"}`}
                  onMouseEnter={() => {
                    setActiveToggle(key);
                    if(width > 1024) {
                      setCurrentBox(i);
                    }
                  }}
                  onMouseLeave={() => {
                    setCurrentBox(0);
                    setActiveToggle(false);
                  }}
                  onTouchStart={() => {
                    console.log("touch box");
                    setActiveToggle(key);
                  }}
                  onTouchEnd={() => {
                    //setCurrentBox(0);
                    //setActiveToggle(false)
                  }}
                >
                  <div className="paragraph-hover">
                    {ReactHtmlParser(i.title)}
                    <span className="indicator indicator-hover">
                      <i className="icon-plus" />
                    </span>
                    {props.content ? (
                   <BackgroundImage critical={true}
                     className={`content ${
                       toggle === key ? "selected" : "deselected"
                     }`}
                     fluid={i.img.childImageSharp.fluid}
                   >
                     {ReactHtmlParser(i.content)}
                   </BackgroundImage>
                 ) : (
                   <Img critical={true} fluid={i.img.childImageSharp.fluid}></Img>
                 )}
                  </div>
                </a>
              
              </div>
            );
          })}
        </div>
      </Container>
    </StyledBoxes>
  );
};

export default Boxes;
