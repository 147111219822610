import React, {useState} from 'react'
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { rhythm, scale } from "../utils/typography";
import { Container } from "../Elements/Container";
import ReactHtmlParser from "react-html-parser";
import * as yup from "yup";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import textSpanish from "../img/espanol-2.svg";
import textEnglish from "../img/ingles-2.svg";

import Axios from "axios";

const StyledForm2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 calc(5vw - 10px);
  padding-bottom: ${rhythm(2)} !important;
  flex-direction: column;
  align-self: flex-start;
  label {
    padding: 0;
    display: flex;
    color: red;
    font-weight: 500;
  }
  .submit-group {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin: 8px;
    display: block;
    margin: auto;
    margin-right: 6px;
    @media screen and (max-width: 1024px) {
      align-self: center !important;
      margin-right: auto !important;
    }
  }
  p {
    margin-top: 1rem;
    text-align: center;
  }
  .button {
    padding: 10px 50px !important;
    font-size: 24px;
    font-family: Bebas Neue Bold;
    background: #91c508;
    min-width: 170px;
    color: white;
    width: 100%;
    outline: none !important;
    margin-top: 10px !important;
    margin: 0 auto;
    margin-right: 10px !important;
    border: none !important;
    &:hover {
      background: #222;
    }
  }
  hr {
    display: flex;
    width: 100%;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    .main {
      width: 100%;
    }
    .half {
      @media screen and (min-width: 1025px) {
        flex-basis: 50%;
      }
    }
    .captcha {
      margin-top: ${rhythm(0.5)};
    }
    button {
      padding: 10px 50px !important;
      font-size: 24px;
      font-family: Bebas Neue Bold;
      background: #91c508;
      width: 100%;
      color: white;
      outline: none !important;
      margin-top: 10px !important;
      margin: 0 auto;
      border: none !important;
      min-width: 170px;
      &:hover {
        background: #222;
      }
    }
    span {
      padding: 10px;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      .button,
      input,
      optgroup,
      select,
      textarea {
        width: 100%;
        background: #fff;
        color: #555;
        width: 100%;
        font-weight: 400;
        padding: 10px;
        border: 1px solid #555;
        outline: none;
        &:focus {
          outline: 2px solid #91c508;
        }
      }
    }
  }

  .icon-mail-container {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    .icon-email-icon-contact {
      font-size: 3rem;
    }
  }

  .checkbox-container {
    margin: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: center;

    @media(min-width: 1024px){
      justify-content: flex-end;
      margin-right: 1rem;
    }
  }

  .checkbox-container {
    margin: 1rem 0;
    display: flex;
    justify-content: center;

    @media(min-width: 1024px){
      justify-content: flex-end;
      margin-right: 1rem;
    }
  }

  .text-checkbox{

    display: block;
    text-align: right;
    color: #222;
    font-family: 'Roboto';
    margin-left: 0.5rem;
    font-size: 14px;
    padding: 0 !important;

    a {
      color: #222;
      text-decoration: underline;
    }

    a:hover {
      color: #c2c2c2;
    }

    @media(min-width: 1024px) {
      text-align: right;
      margin-right: 8px;
    }
  }

  .cbox1 {
    margin-top: 0rem !important;
  }

  .cbox1:checked{
    background-color: green;
  }
`;

export const ContactForm = (props) => { 

    console.log("props props ",props);

    const [check,setCheck] = useState(false);

    const messages = {
        isRequired: () => {
          return props.language === "es"
            ? "* Este campo es requerido"
            : "* This field is required";
        },
        notDefault: () => {
          return props.language === "es"
            ? "* El valor de este campo no puede ser el valor por defecto"
            : "* The value of this field cannot be the default one";
        },
        validEmail: () => {
          return props.language === "es" ? "* Email no valido" : "* Invalid Email";
        },
        validPhone: () => {
          return props.language === "es"
            ? "* Telefono no valido, EJ: 00584121234567"
            : "* Invalid Phone, EX: 00584121234567";
        },
      };
      const schema = yup.object().shape({
        subject: yup.string().required(messages.isRequired()),
        name: yup.string().required(messages.isRequired()),
        lastName: yup.string().required(messages.isRequired()),
        email: yup
          .string()
          .email(messages.validEmail())
          .required(messages.isRequired()),
        phone: yup
          .string()
          .notOneOf(["default"], messages.notDefault())
          .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            messages.validPhone()
          )
          .required(messages.isRequired()),
        city: yup.string().required(messages.isRequired()),
        country: yup.string().required(messages.isRequired()),
        message: yup.string().required(messages.isRequired()),
      });
      
      const { register, handleSubmit, watch, errors } = useForm({schema});
      const [recaptcha, setRecaptcha] = useState(false);
      const [error, setError] = useState(false);

      const onSubmit = (data) => {
       
        if (!recaptcha) {
          setError(true);
          return null;
        } 
        
        const body = { ...data, time: Date.now() };

        let headers = {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
      };

        Axios.post("https://dentalvipcaracas.com:8000/contact", body, headers)
          .then((data) => {
        
            navigate(
              `${
                typeof window !== 'undefined' 
                    && window.localStorage.getItem("lang") === "es"
                  ? "/gracias-por-contactarnos/"
                  : "/en/thank-you/"
              }`
            );
          })
          .catch((error) => {
            return error;
          }); 
      };


      return (
          <>
            <StyledForm2>
                <hr className="border-form" />
                <div className="footer-warning">
                    <div className="warning-container">
                      <div className="icon-mail-container">
                      <span class="icon-email-icon-contact"></span>
                      </div>
                      <p className="warning-text">
                        <img src={props.language === 'es' ? textSpanish : textEnglish}
                             width="300"
                             height="16.49"  />
                      </p>
                    </div>
                  </div>
                <form
                    name="Specialties Form"
                    onSubmit={handleSubmit(onSubmit)}
                    className="row"
                >
                    {props.data.fields.map((i, k) => {
                        return (
                            <>
                                {i.type !== "textarea" && i.type !== "select" ? (
                                    <span key={k} className={i.name === "subject" ? " " : "half"}>
                                        {" "}
                                        <input
                                            type={i.type}
                                            name={i.name}
                                            placeholder={i.placeholder}
                                            ref={register({required: true})}
                                        />{" "}
                                        {errors[i.name] && <label>{/*errors[i.name].message*/}
                                        {props.data.warning}</label>}
                                    </span>
                                ) : (
                                    <span>
                                        <textarea
                                            placeholder={i.placeholder}
                                            rows="6"
                                            cols="40"
                                            name={i.name}
                                            ref={register({required: true})}
                                        ></textarea>
                                        {errors[i.name] && <label>{/*errors[i.name].message*/}
                                        {props.data.warning}</label>}
                                    </span>
                                )}
                            </>
                        );
                    })}

                            <div className="checkbox-container">
                              <label className='label-checkbox'>
                                <input type="checkbox" 
                                       class="cbox1" 
                                       value={check}  
                                       ref={register({required: true})} 
                                      onChange={(event) => setCheck(event.target.checked)}
                                      required /> 
                                {props.language == 'es' ? 
                                  <span className='text-checkbox'>
                                      He leído y acepto la <a href='https://dentalvipcaracas.com/politica-de-privacidad/'>
                                                  Política de Privacidad
                                                </a>
                                  </span>   
                              : <span className='text-checkbox'>
                                    I have read and accept the <a href='https://dentalvipcaracas.com/en/privacy-policy/'>
                                                    Privacy Policy
                                                 </a>
                            </span>}
                              
                              </label>
                            </div>

                        <div className="submit-group">
                            <ReCAPTCHA
                                className="captcha"
                                /*sitekey="6LfMI6YZAAAAAO1akvBG2ILmPfl8mEmZoHl4KWNS"*/
                                sitekey="6Lfm1GYaAAAAAI2I-APy8sALURS-nKT89MhHOJDZ"
                                onChange={() => {
                                    setRecaptcha(true);
                                }}
                            />
                            {error && !recaptcha && (
                                <label style={{ padding: "0 15px" }}>
                                {props.language === "es"
                                    ? "Debe confirmar que no es un robot"
                                    : "You need to confirm that you are not a robot"}
                                </label>
                            )}

                            <button type="submit">
                                {props.language === "es" ? "ENVIAR" : "SEND"}
                            </button>
                        </div>
                </form>
            </StyledForm2>
          </>
      );

};