import React, {useState, useLayoutEffect} from "react";
import Layout from "../layout";
import SetLang from "../components/setLang";
import Boxes from "../components/boxesContact";
import Hero from "../components/hero";
import Heading from "../components/heading";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Parallax from "../components/parallax";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import contactImg from "../img/qdc-contacto.jpg";
import { ContactWidget } from "../components/ContactWidget";
import { ContactPopUp } from "../components/contactPopUp";
import { ContactForm } from "../components/contactForm";

import medeChacao from "../img/mede-alcaldia-logos.svg"

import Form from "../components/form";
const StyledForm = styled.section`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .dv-contact-info {
      max-width: 100% !important;
    }
  }

  .dv-contact-info {
    width: 100%;
    align-items: center;
    background: #202020;
    color: white;
    padding: 5rem calc(5vw) 3rem;
    display: flex;
    max-width: 600px;
    h3,
    h2 {
      font-family: "Bebas Neue Bold";

      @media(max-width: 1024px){
        margin-bottom: 1rem;
      }
    }
    i {
      color: #999;
      margin-right: 20px;
      font-size: 1.5rem;
    }
    .dv-underline {
      text-decoration: underline;
    }
    p {
      margin-left: ${rhythm(2)};
    }
  }
`;

const Map = styled.section`
  iframe {
    width: 100%;
    min-height: 60vh;
    border: none;
    margin-bottom: 0;
    @media screen and (max-width: 850px) {
      min-height: 70vh;
    }
  }
`;

const ButtonContactContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
  margin-top: -2rem;
`;

const Button = styled.div`
    width: 177px;
    height: 40px;
    border: 1px solid rgb(34, 34, 34);
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    margin: 0 auto;
    &:hover {
        background-color: #ededed;
        border: none;
    }

    
`;


export const ContactPageTemplate = ({
  hero,
  language,
  amenities,
  parallax,
  forms,
  heading,
}) => {

  const [isContactWidgetOpen, setIsContactWidgetOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPortrait,setIsPortrait] = useState(false);

  const changeOpenWidget = () => {
    /*if(isMobile){
      if(isPortrait){
        setIsContactWidgetOpen(!isContactWidgetOpen);
      }
    }else{
      setIsContactWidgetOpen(!isContactWidgetOpen);
    } */
    setIsContactWidgetOpen(!isContactWidgetOpen);
  };

 
  return (
    <div>
      {isContactWidgetOpen == true ? (
          <>
           <ContactPopUp changeOpenWidget={changeOpenWidget} />
          </>
        ) : <></>}
      <Hero className="center single half" {...hero}></Hero>
      <Heading {...heading} />
      <ButtonContactContainer>
        <Button onClick={() => changeOpenWidget()}>
          {language === 'es' ? 'CONTACTO' : 'CONTACT'}
        </Button>
      </ButtonContactContainer>
      <StyledForm>


      <div className="dv-contact-info">
              <div className="dv-main-menu-left">
                <h2 className="dv-company">
                  DENTAL VIP, Especialidades Odontológicas s.c.
                </h2>
                <br></br>
                <h3>
                  <i className="icon-phone phone" />
                    <span>{language === "es" ? "Teléfonos" : "Phones"} </span>
                </h3>
                <p>
                  +58 <em>(212)</em> 261.3732 <br />
                  +58 <em>(212)</em> 261.3331 <br />
                  +58 <em>(212)</em> 261.5251

                </p>
                <br></br>
                <h3>
                  <i className="icon-map-marker-alt" />
                  <span>{language === "es" ? "Dirección" : "ADDRESS"}</span>
                </h3>
                <p>
                  Multicentro Empresarial del Este,{" "}
                  <br className="hidden-xs hidden-sm visible-md visible-lg" />
                    {language === "es"
                        ? "Torre Miranda, Núcleo A, Piso 14, Oficina A-143, Chacao, Caracas,"
                        : "Miranda Tower, Nucleus A, 14th Floor, Office A-143, Chacao, Caracas,"}
                    {language === "es"
                        ? " Venezuela. C.P. 1071"
                        : " Venezuela. P.C. 1071"}
                </p>
                <br></br>
                <h3>
                  <i className="icon-clock" />
                  <span>
                    {language === "es"
                        ? "Horario de atención"
                        : "CUSTOMER SERVICE HOURS"}
                  </span>
                </h3>
                <p>
                  {language === "es" ? "Lunes a Viernes" : "Monday to Friday"}
                  <br></br>
                  9:00 am - 5:00 pm<br></br>
                  <span className="dv-underline">
                    {language === "es" ? "PREVIA CITA" : "BY APPOINTMENT"}
                  </span>
                </p>
                <br></br>
                <img src={medeChacao} style={{
                  width:"100%",
                  height: "60px"
                }} />
            </div>
          </div>
      <ContactForm data={forms.contact} language={language}></ContactForm>

            
        
      </StyledForm>

      <Map>
        <iframe src="https://snazzymaps.com/embed/72109" />
      </Map>
      {parallax.display && <Parallax {...parallax} />}
      <Boxes content={true} {...amenities}></Boxes>
    </div>
  );
};

const ContactPage = ({ data }) => {

  

  const {
    templateKey,
    language,
    title,
    description,
    keywords,
    redirects,
    hero,
    amenities,
    parallax,
    heading,
  } = data.markdownRemark.frontmatter;

  const [isContactWidgetOpen, setIsContactWidgetOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPortrait,setIsPortrait] = useState(false);

  const changeOpenWidget = () => {
    /*if(isMobile){
      if(isPortrait){
        setIsContactWidgetOpen(!isContactWidgetOpen);
      }
    }else{
      setIsContactWidgetOpen(!isContactWidgetOpen);
    } */
    setIsContactWidgetOpen(!isContactWidgetOpen);
  };

  useLayoutEffect(() => {

    if(typeof window !== "undefined"){
      
      const updateSize = () => {

          let width = window.screen.width;
          let height = window.screen.height;

          if(width < 1026){
            setIsMobile(true);
          }else{
      
            setIsMobile(false);
          }

          if(height > width){
      
            setIsPortrait(true);
          }else{
     
            setIsPortrait(false);
          }
      };

      window.addEventListener("resize",updateSize);
    }
  });

  return (
    <Layout>
      <SetLang language={language} link={redirects} />
      <SEO
        title={title}
        lang={language}
        description={description}
        keywords={keywords}
      />
      <ContactPageTemplate
        {...{
          templateKey,
          language,
          parallax,
          title,
          redirects,
          hero,
          amenities,
          heading,
        }}
      />

      <div>
        <ContactWidget changeOpenWidget={changeOpenWidget}/>

        {isContactWidgetOpen == true ? (
          <>
           <ContactPopUp changeOpenWidget={changeOpenWidget} />
          </>
        ) : <></>}

      </div>
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "contact-page" } }
    ) {
      frontmatter {
        language
        title
        keywords
        description
        redirects
        heading {
          display
          content
        }
        hero {
          background {
            scaleOnReveal
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [1500], quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            isParallax
          }
          anim {
            display
            type
          }
          height
          indicator
          portraitPosition
          content {
            position
            body
          }
        }

        parallax {
          display
          portraitPosition
          img {
            childImageSharp {
              fluid(srcSetBreakpoints: [1900], quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          content
        }

        amenities {
          title
          procedures {
            title
            to
            content
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [550], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
